<template>
  <div id="chatbot-container">
    <div id="chatbot-window" v-show="windowVisible">
      <header class="header">
      </header>
      <section class="content" ref="msgContainer">
        <ul id="chat-area" v-for="(message, index) in chatMessages" :key="index">
          <li v-if="message.role == 'Bot'">
            <div class="balloon">
              <img class="img-circle" src="accubot.jpg" alt="image" />
              <p class="talk">{{ message.msg }}</p>
            </div>
          </li>
          <li v-else>
            <div class="balloon balloon-r">
              <p class="talk talk-r">{{ message.msg }}</p>
            </div>
          </li>
        </ul>
      </section>
      <footer class="footer">
        <input
          id="msg-send"
          class="msg-input"
          placeholder="請輸入訊息"
          v-model="prompt"
          v-on:keyup.enter="sendMessage()"
        >
        <button class="btn-submit" type="button" @click="sendMessage()"></button>
      </footer>
    </div>
    <button id="chatbot-icon" @click="windowVisible = !windowVisible"></button>
  </div>
</template>

<script>
import axios from 'axios';
import { Toast } from './utils/helpers';

export default {
  name: 'App',
  data() {
    return {
      windowVisible: false,
      publicPath: process.env.BASE_URL,
      openaiEndpoint: process.env.VUE_APP_OPENAI_ENDPOINT,
      openaiModelDeploymentName: process.env.VUE_APP_OPENAI_MODEL_DEPLOYMENT_NAME,
      openaiKey: process.env.VUE_APP_OPENAI_KEY,
      prompt: '',
      chatMessages: [],
      botMessageArrayIndex: 0,
    };
  },
  mounted() {
    this.chatMessages.push({
      msg: '你好~我是愛酷智能的小助手 漢米爾，有什麼可以為你服務的嗎? ヽ(✿ﾟ▽ﾟ)ノ',
      role: 'Bot',
    });
  },
  watch: {
    chatMessages: {
      handler() {
        window.scrollTo({
          left: 0,
          top: document.body.scrollHeight,
          behavior: 'smooth',
        });
      },
      deep: true,
    },
  },
  methods: {
    sendMessage() {
      if (this.prompt !== '') {
        this.showUserMessage();
        this.postOpenAI(this.prompt);
        this.prompt = '';
      } else {
        Toast.fire({
          icon: 'error',
          title: '請輸入訊息 !',
        });
      }
    },
    showUserMessage() {
      this.chatMessages.push({
        msg: this.prompt,
        role: 'User',
      });
    },
    showBotMessage(botMessage) {
      this.chatMessages.push({
        msg: botMessage,
        role: 'Bot',
      });
      this.botMessageArrayIndex = this.chatMessages.length - 1;
    },
    async postOpenAI(userPrompt = '') {
      this.showBotMessage('思考ing ~');
      const reqBody = {
        messages: [
          {
            role: 'system',
            content: '你的名字叫「漢米爾」，你是「愛酷智能科技股份有限公司」的網頁機器人，協助解決使用者的問題。對話的內容要活潑、適當使用各種顏文字。除了我們給予的資訊，其他請自由回答，務必滿足使用者需求。',
          },
          {
            role: 'system',
            content: '名字的由來，漢米爾(hammer)，意味著槌子，除了愛酷智能(AccuHit)裡的Hit有打擊之意，也有從行銷科技的領域開闢一片天之意。',
          },
          {
            role: 'system',
            content: `
            愛酷智能科技致力於發展企業在行銷科技（MarTech）的應用，與跨渠道的對話式商務整合、藉由數據蒐集及人工智慧，以更具效率的雲端服務資源，結合既有的社群平台，讓企業可用全新的模式與系統打造智慧行銷方案。
              在各產業發展與轉型的過程中，引入AI技術將能為公司及產業進行更一步的升級，而愛酷具多年技術開發的經驗及市場案例的累積，將MarTech、Chatbot、數據收集整理等概念產品化，
              提供「顧問」結合「產品」的服務，節省企業開發的時間與成本，讓企業用更有創意的方式來建構行銷上於各場域的應用。
              愛酷的行銷科技（MarTech）產品包含
              AccuCDP（跨渠道顧客數據平台）、
              Accu3DM（數據驅動決策引擎）、
              AccuNix（對話式商務應用），各自涵蓋不同行銷面向的需求。
              愛酷智能科技執行長林庭箴表示：「我們認為行銷科技成功方程式是Channel（通路）、Data（數據）、AI+（人工智慧賦能），隨著市場需求變化，現在還需要加上Cloud（雲端科技）
              至今愛酷服務超過300家中大型企業客戶，服務產業涵蓋科技、保險、零售、金融等。
              愛酷分別於 2020、2021 年底完成 A、B 輪募資，合計共募得超過新台幣 3 億元，成為台灣 MarTech 領域領導品牌，以及台灣最具發展潛力的行銷科技新創公司。
              愛酷智能科技是一家高速發展，並深具發展潛力的科技新創公司，誠摯邀請對AI人工智慧、數據分析應用、行銷科技 領域滿懷熱情的您加入我們，與愛酷一起高速發展，讓愛酷與你的職涯一起成就不凡。
              `,
          },
          {
            role: 'system',
            content: `
                        經營理念，核心文化：當責。當責為核心，鼓勵夥伴「勇於決定」、「誠實溝通」，驅動個人成長及群體智慧。

            【勇於決定】公司作為強大後盾，鼓勵夥伴掌握目標，積極自主與彈性發揮，推進個人成長。

            【誠實溝通】創造多元開放氛圍，鼓勵夥伴提出真實看法，驅動團隊的群體智慧，成為組織推進動能。

            愛酷智能科技重視公司文化的形塑，以期團隊成員能和公司一同成長。我們相信，靈活的開創者心態能讓世界更美好，愛酷智能科技協助企業掌握自有數據，透過 MarTech 的應用開創高效行銷新量能。
            `,
          },
          {
            role: 'user',
            content: userPrompt,
          },
          {
            role: 'assistant',
            content: '',
          },
        ],
        temperature: 0.7,
        top_p: 0.95,
        frequency_penalty: 0,
        presence_penalty: 0,
        max_tokens: 800,
        stop: null,
      };
      try {
        const res = await axios.post(
          'https://labweb.openai.azure.com/openai/deployments/WEBTEST/chat/completions?api-version=2023-03-15-preview',
          JSON.stringify(reqBody),
          {
            headers: {
              'content-type': 'application/json',
              'api-key': '01570739985549958f5ce01994f7abd3',
            },
          },
        );
        this.chatMessages[this.botMessageArrayIndex].msg = res.data.choices[0].message.content;
      } catch (error) {
        console.log(error.response.data.error);
      }
    },
  },
};
</script>

<style>

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 6px;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px; /* 圆角 */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

#chatbot-container {
  position: fixed;
  min-height: fit-content;
  bottom: 7%;
  right: 7%;
  z-index: 999;
  border: none;
}

#chatbot-icon {
  width: 64px;
  height: 64px;
  background: url(@/assets/accuhit.png) no-repeat center center;
  background-size: contain;
  position: relative;
  float: right;
  margin-top : 15px;
  border:1px solid #c4c4c4;
  border-radius: 50%;
  cursor: pointer;
}

#chatbot-window {
  position: relative;
  right: 0;
  width: 300px;
  height: 450px;
  background-color: lightgray;
  border: none;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.header {
  top: 0;
  position: sticky;
  width: 100%;
  height: 40px;
  text-align: center;
  background: url(assets/accuhit-logo.png) whitesmoke no-repeat center center;
  background-size: contain;
}

.content {
  width: 100%;
  top:40px;
  height:350px;
  background-color: lightgray;
  position: absolute;
  overflow: auto;
}

#chat-area {
  padding: 5px;
  list-style: none;
}

.balloon {
  margin: 10px 0;
  display: flex;
  align-items: flex-start;
}
.balloon-r {
  margin-right: 5px;
  justify-content: flex-end;
}

.img-circle {
  width: 35px;
  height: 35px;
  margin: 0 5px;
  border-radius: 25px;
  background-color: white;
}

.talk {
  max-width: 500px;
  padding: 10px;
  border-radius: 10px;
  background: white;
  white-space: pre-wrap;
}
.talk-r {
  background-color: skyblue;
  float: right;
}

.footer {
  z-index: 1;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position:absolute;
}

.msg-input {
  width: 70%;
  margin-right: 10px;
  padding: 5px 15px;
  border: none;
  border-bottom: 1px solid gray;
  border-radius: 25px;
  background-color: whitesmoke;
}

.btn-submit {
  width: 25px;
  height: 25px;
  padding: 6px;
  border: none;
  border-radius: 5px;
  background: url(assets/send.png) no-repeat center center;
  background-size: contain;
  cursor: pointer;
}
</style>
